import React,{useState} from "react";
import GalleryComp from "./components/Gallery";
import GlobalStyles from "./components/GlobalStyles";
import Loading from "./components/Loading";
import Main from "./pages/Main";
import NewMain from "./pages/NewMain"; 
import { useStore } from "./store/store";


export default function App() {
  const {showGallery,plansGallery } = useStore();
  return (
    <div className="App">
      <GlobalStyles />
      <Loading />
      <NewMain showGallery = {showGallery}/>
      {
        showGallery ?
        <GalleryComp plansGallery = {plansGallery} /> : null
      }
    </div>
  );
}
