import { motion } from "framer-motion";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { useStore } from "../store/store";
import { Navigation, Pagination } from "swiper";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function GalleryComp({plansGallery}) {
  const { setShowGallery,setPlansGallery } = useStore();

  const handleClose = () =>
  {
    setPlansGallery(false)
    setShowGallery(false)
  }

  return (
    <StyledGallery
      animate={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
      initial={{ opacity: 0, y: 100 }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <button className="close" onClick={handleClose}>
        <MdClose />
      </button>
      <div className="gallery">
        <div className="slider-preview">
          {
            plansGallery 
            ?
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              modules={[Navigation, Pagination]}
              navigation
              pagination={{ clickable: true }}
              className = "plansSlider"
            >
              <SwiperSlide>
                  <img src={`/BrainYard-Plans/5.png`} alt= "" height="100%" width = "100%"/>                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/BrainYard-Plans/4.png`} alt= "" height="100%" width = "100%"/>                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/BrainYard-Plans/3.png`} alt= "" height="100%" width = "100%" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/BrainYard-Plans/2.png`} alt= "" height="100%" width = "100%" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/BrainYard-Plans/1.png`} alt= "" height="100%" width = "100%"/>                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/BrainYard-Plans/g.png`} alt= "" height="100%" width = "100%"/>                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/BrainYard-Plans/lg.png`} alt= "" height="100%" width = "100%"/>                  
              </SwiperSlide>
            </Swiper>
            :
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              modules={[Navigation, Pagination]}
              navigation
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                  <img src={`/Gallery/1.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <div  className="fullScreenVid">
                    <ReactPlayer
                      url="https://vimeo.com/491998541"
                      controls={true}
                      height="731px"
                      width="100%"
                    />
                  </div>                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/2.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/3.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/4.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/5.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/6.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/7.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/8.jpg`} alt= ""/>                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/9.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/10.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/11.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/12.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/13.jpg`} alt= ""/>                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/14.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/15.jpg`} alt= "" />                  
              </SwiperSlide>
              <SwiperSlide>
                  <img src={`/Gallery/16.jpg`} alt= ""/>                  
              </SwiperSlide>
          </Swiper>
        }  
        </div>
      </div>
    </StyledGallery>
  );
}

const StyledGallery = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.6);
  color: #d7b176;
  z-index: 110;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    color: #d7b176;
  }
  
  .close {
    /* height: 20%; */
    border-radius: 50%;
    aspect-ratio: 1;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    background: transparent;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 2rem;
    z-index:200;
    padding: 0.5rem;
   
  }
  .gallery {
    /* padding: 2rem; */
    height: 95%;
    width: 100%;
    background-color: rgba(128,128,128,0);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .swiper-pagination-bullet{
    opacity: 1;
    background: #fff;
    width: 10px;
    height: 10px;
  }
  
  .swiper-pagination-bullet.swiper-pagination-bullet-active{
    opacity:1;
    background: #000;
  }

  .swiper-button-next::after, .swiper-button-prev::after {
    opacity:1;
    color:#fff;
  }
  
  .slider-preview {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    .swiper {
      height: 95%;
      width: 95%;
    }

    .plansSlider {
      height: 100%;
      width: 100%;
    }

    .swiper-wrapper {
      /* display: grid; */
    }
    .swiper-slide {
      height: 100%;
      width: 100%;
      margin: auto auto;
      display:flex;
      justify-content:center;

      img,.fullScreenVid {
        max-width: 90%;
        height: auto;
        object-fit: contain;
      }

      .fullScreenVid {
        width:900px;
      }
    }
  }
`;
