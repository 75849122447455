import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useStore } from "../../store/store";
import "./building.styles.css";

const IOSSwitch = styled((props) => (
	<Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 23,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 1.5,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: "#FF7B4A",
				opacity: 1,
				border: 0,
			},
			"& .MuiSwitch-thumb": {
				backgroundColor: "#fff",
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color: "#cccccc",
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 20,
		color: "#FF7B4A",
		position: "relative",
		bottom: "1px",
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: "#C8C8C8",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

const Building = ({
	currentContent,
	setCurrentContent,
	setPreviousContent,
}) => {
	const { currentFloor, setCurrentFloor } = useStore();
	const [showButton, setShowButton] = useState(true);

	window.reactActions.setShowButton = setShowButton;

	useEffect(() => {
		if (currentContent === "building") {
			if (currentFloor > 5) setCurrentFloor(6);
			if (currentFloor < 0) setCurrentFloor(0);
		}
	}, [currentContent]);

	useEffect(() => {
		window.krpano && window.changeFloor(currentFloor);
	}, [currentFloor]);

	const handleClick = (e) => {
		setCurrentFloor(parseInt(e.target.id));
		window.krpano && window.changeFloor(parseInt(e.target.id));
	};

	const changeNav = () => {
		setPreviousContent("building");
		setCurrentContent("plans");
	};

	const changeNav1 = () => {
		setPreviousContent("building");
		setCurrentContent("gallery");
	};

	const handleswitchchange = () => {
		window.toggleFurnish();
	};

	useEffect(() => {
		currentContent === "building" &&
			!window.krpano &&
			window.embedpano({
				xml: "/tour.xml",
				target: "pano",
				html5: "only",
				mobilescale: 1.0,
				passQueryParameters: "startscene,startlookat",
				onready: window.init,
			});
	}, [currentContent]);

	return (
		<div
			className='building'
			style={{ bottom: `${currentContent === "building" ? "0" : ""}` }}
		>
			{currentContent === "building" ? (
				<div className='floorsListBuilding'>
					<div
						className='fifthFloor'
						id='6'
						onClick={handleClick}
						style={{
							top: "8%",
							color: `${currentFloor === 6 ? "#FF7B4A" : "#8F604E"}`,
						}}
					>
						Overview
					</div>
					<div
						className='fifthFloor'
						id='5'
						onClick={handleClick}
						style={{
							top: "10.5%",
							color: `${currentFloor === 5 ? "#FF7B4A" : "#8F604E"}`,
						}}
					>
						Fifth Floor
					</div>
					<div
						className='fifthFloor'
						id='4'
						onClick={handleClick}
						style={{
							top: "13%",
							color: `${currentFloor === 4 ? "#FF7B4A" : "#8F604E"}`,
						}}
					>
						Fourth Floor
					</div>
					<div
						className='fifthFloor'
						id='3'
						onClick={handleClick}
						style={{
							top: "15.5%",
							color: `${currentFloor === 3 ? "#FF7B4A" : "#8F604E"}`,
						}}
					>
						Third Floor
					</div>
					<div
						className='fifthFloor'
						id='2'
						onClick={handleClick}
						style={{
							top: "18%",
							color: `${currentFloor === 2 ? "#FF7B4A" : "#8F604E"}`,
						}}
					>
						Second Floor
					</div>
					<div
						className='fifthFloor'
						id='1'
						onClick={handleClick}
						style={{
							top: "20.5%",
							color: `${currentFloor === 1 ? "#FF7B4A" : "#8F604E"}`,
						}}
					>
						First Floor
					</div>
					<div
						className='fifthFloor'
						id='0'
						onClick={handleClick}
						style={{
							top: "23%",
							color: `${currentFloor === 0 ? "#FF7B4A" : "#8F604E"}`,
						}}
					>
						Ground Floor
					</div>
				</div>
			) : null}

			<div className='navbar'>
				<div className='viewFullScreenPlan'>
					View{" "}
					<span
						className='linkFullScreenPlan'
						style={{ color: "#FF7B4A", cursor: "pointer" }}
						onClick={changeNav}
					>
						Plans
					</span>
				</div>
				<div className='viewFullScreenPlan'>
					View{" "}
					<span
						className='linkFullScreenPlan'
						style={{ color: "#FF7B4A" }}
						onClick={changeNav1}
					>
						Gallery
					</span>
				</div>
				<div
					className='viewFullScreenPlan'
					style={{ color: "#FF7B4A", zIndex: 50 }}
				>
					Furnished
				</div>
				<div
					className='viewFullScreenPlan'
					style={{ color: "#FF7B4A", padding: 0, zIndex: 50 }}
				>
					<FormGroup onChange={handleswitchchange}>
						<FormControlLabel
							control={<IOSSwitch sx={{ m: 1 }} disabled={!showButton} />}
						/>
					</FormGroup>
				</div>
			</div>

			<div className='buildingCompContent'>
				<div
					id='pano'
					style={{
						width: "100%",
						height: "calc(100% - 120px)",
						marginTop: "120px",
					}}
				>
					<noscript>
						<table style={{ width: "100%", height: "100%" }}>
							<tr style={{ verticalAlign: "middle" }}>
								<td>
									<div style={{ textAlign: "center" }}>
										ERROR:
										<br />
										<br />
										Javascript not activated
										<br />
										<br />
									</div>
								</td>
							</tr>
						</table>
					</noscript>
				</div>
			</div>
		</div>
	);
};

export default Building;
