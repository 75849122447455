import React from "react";
import { useStore } from "../../store/store";
import AreaCalculator from "../AreaCalculator";
import "./availability.styles.css"

const Availability = ({currentContent}) =>
{
    const {
        selected6th,
        selected5th,
        selected4th,
        selected3rd,
        selected2nd,
        selected1st,
        selected0th,
        selectedLg
    } = useStore();

    return(
        <div className="availability" style={{top:`${currentContent === "availability" ? "0" :""}`}}>
            <div className="availHeader"></div>   
            <AreaCalculator/>
            <div className="fullModal">
                <img src={`/availability/Full.png`} className = "availFullImg" height = "90%" width = "90%"/>
            </div>
            {
                selected6th
                ?
                <div className="fullModal">
                    <img src={`/availability/6.png`} className = "availOverLayImg" height = "90%" width = "90%" />
                </div>
                : null
            }
            {
                selected5th
                ?
                <div className="fullModal">
                    <img src={`/availability/5.png`} className = "availOverLayImg" height = "90%" width = "90%" />
                </div>
                : null
            }
            {
                selected4th
                ?
                <div className="fullModal">
                    <img src={`/availability/4.png`} className = "availOverLayImg" height = "90%" width = "90%" />
                </div>
                : null
            }
            {
                selected3rd
                ?
                <div className="fullModal">
                    <img src={`/availability/3.png`} className = "availOverLayImg" height = "90%" width = "90%" />
                </div>
                : null
            }
            {
                selected2nd
                ?
                <div className="fullModal">
                    <img src={`/availability/2.png`} className = "availOverLayImg" height = "90%" width = "90%" />
                </div>
                : null
            }
            {
                selected1st
                ?
                <div className="fullModal">
                    <img src={`/availability/1.png`} className = "availOverLayImg" height = "90%" width = "90%" />
                </div>
                : null
            }
            {
                selected0th
                ?
                <div className="fullModal">
                    <img src={`/availability/0.png`} className = "availOverLayImg" height = "90%" width = "90%" />
                </div>
                : null
            }
            {
                selectedLg
                ?
                <div className="fullModal">
                    <img src={`/availability/0.png`} className = "availOverLayImg" height = "90%" width = "90%" />
                </div>
                : null
            }
        </div>
    )
}

export default Availability