import React from "react";
import "./specification.styles.css"

const Specification = ({currentContent}) =>
{
    return(
        <div className="specification" style={{top:`${currentContent === "specification" ? "0" :"" }`}}>
            <div className="specificationContent"/>
        </div>
    )
}

export default Specification