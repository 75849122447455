import styled from "styled-components";
import { useProgress } from "@react-three/drei";

export default function Loading() {
  const { progress } = useProgress();

  return (
    <StyledLoading progress={progress}>
      <img src="/EIB_BrainYard_Logo_RGB.png" alt="" />
      <p>Loading... {Math.round(progress)}%</p>
    </StyledLoading>
  );
}

const StyledLoading = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  opacity: ${(props) => (props?.progress === 100 ? 0 : 1)};
  pointer-events: ${(props) => (props?.progress === 100 ? "none" : "all")};
  background: linear-gradient(217deg, #062C26, rgba(255,0,0,0) 100%),
              linear-gradient(127deg, #001E60, rgba(0,255,0,0) 100%),
              linear-gradient(336deg, #FF7B4A, rgba(0,0,255,0) 100%);
              linear-gradient(336deg, #360F29, rgba(0,0,255,0) 100%);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 999;
  transition: all 1.5s ease-in-out;
  transition-delay: 0.5s;
  img {
  }
  p {
    margin-top: 2rem;
    font-family:Sharp Grotesk Book;
    font-size:20px;
    background: rgb(999, 999, 999);
    ${'' /* background: linear-gradient(
      45deg,
      rgba(237, 191, 133, 1) 0%,
      rgba(249, 213, 155, 1) 100%
    ); */}
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;
