import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import "./plans.styles.css";
import { useStore } from "../../store/store";

const IOSSwitch = styled((props) => (
	<Switch
		focusVisibleClassName='.Mui-focusVisible'
		disableRipple
		{...props}
		checked={props.toggleFurnished}
	/>
))(({ theme }) => ({
	width: 42,
	height: 23,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 1.5,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: "#2FB292",
				opacity: 1,
				border: 0,
			},
			"& .MuiSwitch-thumb": {
				backgroundColor: "#fff",
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 20,
		color: "#2FB292",
		position: "relative",
		bottom: "1px",
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: "#C8C8C8",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));

const Plans = ({ currentContent, setPreviousContent, setCurrentContent }) => {
	const { currentFloor, setCurrentFloor } = useStore();
	const { setShowGallery, setPlansGallery } = useStore();
	const [toggleFurnished, setToggleFurnished] = useState(false);

	useEffect(() => {
		if (currentContent === "plans" && currentFloor > 5) {
			setCurrentFloor(7);
		}
	}, [currentContent]);

	const handleKeyDown = (e) => {
		if (e.key === "ArrowRight") {
			if (currentFloor !== null) {
				if (currentFloor === -1) {
					return;
				} else if (currentFloor !== 0) {
					setCurrentFloor(currentFloor - 1);
				} else {
					setCurrentFloor(-1);
				}
			}
		} else if (e.key === "ArrowLeft") {
			if (currentFloor !== null) {
				if (currentFloor === 7) {
					return;
				} else if (currentFloor !== 4) {
					setCurrentFloor(currentFloor + 1);
				} else {
					setCurrentFloor(5);
				}
			}
		}
	};

	const HandleViewGallery = () => {
		setPreviousContent("plans");
		setCurrentContent("gallery");
	};

	const handleFullScreenGallery = () => {
		setShowGallery(true);
		setPlansGallery(true);
	};

	const handlefurnished = (e) => {
		if (e.target.checked) {
			setToggleFurnished(true);
		} else {
			setToggleFurnished(false);
		}
	};

	let styleVal = {};
	if (currentContent === "plans") {
		styleVal = {
			top: "0",
		};
	}

	return (
		<div
			className='newPlans'
			style={{ ...styleVal }}
			onKeyDown={handleKeyDown}
			tabIndex='0'
		>
			{currentContent === "plans" ? (
				<div className='floorsList'>
					<div
						className='fifthFloor plansOptions'
						onClick={() => {
							setCurrentFloor(7);
						}}
						style={{
							top: "30%",
							color: `${
								currentFloor === 7 ? "#2FB292" : "rgba(47, 178, 146, 0.5)"
							}`,
						}}
					>
						Transport Plan
					</div>
					<div
						className='fifthFloor plansOptions'
						onClick={() => {
							setCurrentFloor(6);
						}}
						style={{
							top: "32%",
							color: `${
								currentFloor === 6 ? "#2FB292" : "rgba(47, 178, 146, 0.5)"
							}`,
						}}
					>
						Amenities Plan
					</div>
					<div
						className='fifthFloor plansOptions'
						onClick={() => {
							setCurrentFloor(5);
						}}
						style={{
							top: "34%",
							color: `${
								currentFloor === 5 ? "#2FB292" : "rgba(47, 178, 146, 0.5)"
							}`,
						}}
					>
						Fifth Floor
					</div>
					<div
						className='fifthFloor plansOptions'
						onClick={() => {
							setCurrentFloor(4);
						}}
						style={{
							top: "36%",
							color: `${
								currentFloor === 4 ? "#2FB292" : "rgba(47, 178, 146, 0.5)"
							}`,
						}}
					>
						Fourth Floor
					</div>
					<div
						className='fifthFloor plansOptions'
						onClick={() => {
							setCurrentFloor(3);
						}}
						style={{
							top: "38%",
							color: `${
								currentFloor === 3 ? "#2FB292" : "rgba(47, 178, 146, 0.5)"
							}`,
						}}
					>
						Third Floor
					</div>
					<div
						className='fifthFloor plansOptions'
						onClick={() => {
							setCurrentFloor(2);
						}}
						style={{
							top: "40%",
							color: `${
								currentFloor === 2 ? "#2FB292" : "rgba(47, 178, 146, 0.5)"
							}`,
						}}
					>
						Second Floor
					</div>
					<div
						className='fifthFloor plansOptions'
						onClick={() => {
							setCurrentFloor(1);
						}}
						style={{
							top: "42%",
							color: `${
								currentFloor === 1 ? "#2FB292" : "rgba(47, 178, 146, 0.5)"
							}`,
						}}
					>
						First Floor
					</div>
					<div
						className='fifthFloor plansOptions'
						onClick={() => {
							setCurrentFloor(0);
						}}
						style={{
							top: "44%",
							color: `${
								currentFloor === 0 ? "#2FB292" : "rgba(47, 178, 146, 0.5)"
							}`,
						}}
					>
						Ground Floor
					</div>
					<div
						className='fifthFloor plansOptions'
						onClick={() => {
							setCurrentFloor(-1);
						}}
						style={{
							top: "46%",
							color: `${
								currentFloor === -1 ? "#2FB292" : "rgba(47, 178, 146, 0.5)"
							}`,
						}}
					>
						Lower Ground Floor
					</div>
				</div>
			) : null}
			{currentFloor !== null ? (
				<div className='navbar'>
					<div className='viewFullScreenPlan'>
						View{" "}
						<span
							className='linkFullScreenPlan'
							onClick={handleFullScreenGallery}
						>
							Full Screen
						</span>
					</div>
					<div className='viewFullScreenPlan'>
						View{" "}
						<span className='linkFullScreenPlan' onClick={HandleViewGallery}>
							Gallery
						</span>
					</div>
					{currentFloor === 7 || currentFloor === 6 ? null : (
						<>
							<div className='viewFullScreenPlan' style={{ color: "#2FB292" }}>
								Furnished
							</div>
							<div
								className='viewFullScreenPlan'
								style={{ color: "#2FB292", padding: 0 }}
							>
								<FormGroup onChange={handlefurnished}>
									<FormControlLabel control={<IOSSwitch sx={{ m: 1 }} />} />
								</FormGroup>
							</div>
						</>
					)}
				</div>
			) : null}
			{!toggleFurnished ? (
				currentFloor === null ? (
					<div className='introPlanImage' />
				) : currentFloor === 7 ? (
					<div className='TransportPlanImg' />
				) : currentFloor === 6 ? (
					<div className='AmenitiesPlanImg' />
				) : currentFloor === 5 ? (
					<div className='fifthFloorPlanImg' />
				) : currentFloor === 4 ? (
					<div className='fourthFloorPlanImg' />
				) : currentFloor === 3 ? (
					<div className='thirdFloorPlanImg' />
				) : currentFloor === 2 ? (
					<div className='secondFloorPlanImg' />
				) : currentFloor === 1 ? (
					<div className='firstFloorPlanImg' />
				) : currentFloor === 0 ? (
					<div className='groundFloorPlanImg' />
				) : currentFloor === -1 ? (
					<div className='lgFloorPlanImg' />
				) : (
					<div className='introPlanImage' />
				)
			) : null}
			{toggleFurnished ? (
				currentFloor === null ? (
					<div className='introPlanImage' />
				) : currentFloor === 5 ? (
					<div
						className='fifthFloorPlanImg'
						style={{ backgroundImage: "url('./BrainYard-Plans/5_Fur.png')" }}
					/>
				) : currentFloor === 4 ? (
					<div
						className='fourthFloorPlanImg'
						style={{ backgroundImage: "url('./BrainYard-Plans/4_Fur.png')" }}
					/>
				) : currentFloor === 3 ? (
					<div
						className='thirdFloorPlanImg'
						style={{ backgroundImage: "url('./BrainYard-Plans/3_Fur.png')" }}
					/>
				) : currentFloor === 2 ? (
					<div
						className='secondFloorPlanImg'
						style={{ backgroundImage: "url('./BrainYard-Plans/2_Fur.png')" }}
					/>
				) : currentFloor === 1 ? (
					<div
						className='firstFloorPlanImg'
						style={{ backgroundImage: "url('./BrainYard-Plans/1_Fur.png')" }}
					/>
				) : currentFloor === 0 ? (
					<div
						className='groundFloorPlanImg'
						style={{ backgroundImage: "url('./BrainYard-Plans/g_Fur.png')" }}
					/>
				) : currentFloor === -1 ? (
					<div
						className='lgFloorPlanImg'
						style={{ backgroundImage: "url('./BrainYard-Plans/lg_Fur.png')" }}
					/>
				) : (
					<div className='introPlanImage' />
				)
			) : null}
		</div>
	);
};

export default Plans;
