import React, { useState,useEffect } from "react";
import { useStore } from "../../store/store";
import ReactPlayer from "react-player";
import SwiperCore from 'swiper';
import { Navigation, Pagination,Keyboard} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "./gallery.styles.css"

const Gallery = ({currentContent}) =>
{
    const {setShowGallery} = useStore()
    SwiperCore.use([Keyboard]);

    let styleVal = {}
    let optionVal = {}

    if(currentContent === "gallery")
    {
        styleVal = {
            top:"0"
        }

        optionVal = {
            top:"54px"
        }
    }

    return(
        <div className = "galleryComp" style = {{...styleVal}} >
            <div className="navbar">
                <div className="viewFullScreenGallery">View <span className="viewFullGallery" onClick={()=>setShowGallery(true)}>Full Screen</span></div>
            </div>

            <div className="galleryScroll">
                <div className="slider-preview1">
                    <Swiper
                        spaceBetween={1}
                        slidesPerView={1.1}
                        modules={[Navigation, Pagination]}
                        navigation
                        pagination={{ clickable: true }}
                        keyboard = {true}
                    >
                        <SwiperSlide>
                            <div className="galleryImg1" style={{...styleVal}}/>                 
                        </SwiperSlide>
                     
                        <SwiperSlide>
                            <div className="galleryImg2" style={{...styleVal}}/>                  
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg3" style={{...styleVal}}/>                 
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg4" style={{...styleVal}}/>                  
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg5" style={{...styleVal}}/>                 
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg6" style={{...styleVal}}/>                  
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg7" style={{...styleVal}}/>                 
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg8" style={{...styleVal}}/>                  
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg9" style={{...styleVal}}/>                 
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg10" style={{...styleVal}}/>                  
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg11" style={{...styleVal}}/>                 
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg12" style={{...styleVal}}/>                  
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg13" style={{...styleVal}}/>                 
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg14" style={{...styleVal}}/>                  
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg15" style={{...styleVal}}/>                 
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="galleryImg16" style={{...styleVal}}/>                  
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
        </div>
    )
}

export default Gallery
