import { useEffect } from "react";
import styled from "styled-components";
import {PDFDocument} from 'pdf-lib';
import { useStore } from "../store/store";

import { motion } from "framer-motion";


export default function AreaCalculator({main}) {
  const {
    area,
    setArea,
    selected5th,
    selected4th,
    selected3rd,
    selected2nd,
    selected1st,
    selected0th,
    selectedLg,
    setSelected5th,
    setSelected4th,
    setSelected3rd,
    setSelected2nd,
    setSelected1st,
    setSelected0th,
    showUI
  } = useStore();

  const downloadPdfFromArrayBytes = (fileName = 'testFile.pdf', byteArrayResFromAPI) => {
    const arr = new Uint8Array(byteArrayResFromAPI);
    const blob = new Blob([arr]);
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      // Browsers that support HTML5 download attribute
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

  const handleDownload = async () =>
  {
    const url = "./Brainyard_Floorplans.pdf"
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(existingPdfBytes)
    const newPdfDoc = await PDFDocument.create();
    let currentPage = 0;

    const [firstPage] = await newPdfDoc.copyPages(pdfDoc, [0]);
    newPdfDoc.insertPage(currentPage,firstPage) ;
    currentPage = currentPage + 1;

    if(selected0th)
    {
      const [firstPage] = await newPdfDoc.copyPages(pdfDoc, [1]);
      newPdfDoc.insertPage(currentPage,firstPage) ;
      currentPage = currentPage + 1;
    }

    if(selected1st)
    {
      const [firstPage] = await newPdfDoc.copyPages(pdfDoc, [2]);
      newPdfDoc.insertPage(currentPage,firstPage) ;
      currentPage = currentPage + 1;
    }

    if(selected2nd)
    {
      const [firstPage] = await newPdfDoc.copyPages(pdfDoc, [3]);
      newPdfDoc.insertPage(currentPage,firstPage) ;
      currentPage = currentPage + 1;
    }

    if(selected3rd)
    {
      const [firstPage] = await newPdfDoc.copyPages(pdfDoc, [4]);
      newPdfDoc.insertPage(currentPage,firstPage) ;
      currentPage = currentPage + 1;
    }

    if(selected4th)
    {
      const [firstPage] = await newPdfDoc.copyPages(pdfDoc, [5]);
      newPdfDoc.insertPage(currentPage,firstPage) ;
      currentPage = currentPage + 1;
    }

    if(selected5th)
    {
      const [firstPage] = await newPdfDoc.copyPages(pdfDoc, [6]);
      newPdfDoc.insertPage(currentPage,firstPage) ;
      currentPage = currentPage + 1;
    }

    const [lastPage1] = await newPdfDoc.copyPages(pdfDoc, [7]);
    newPdfDoc.insertPage(currentPage,lastPage1) ;
    currentPage = currentPage + 1;
    
    const [lastPage2] = await newPdfDoc.copyPages(pdfDoc, [8]);
    newPdfDoc.insertPage(currentPage,lastPage2) ;
    currentPage = currentPage + 1;

    const pdfBytes = await newPdfDoc.save()
    downloadPdfFromArrayBytes("Brainyard_Floorplans.pdf" ,pdfBytes);
  }

  useEffect(() => {
    setArea(
        (selected5th ? 5619 : 0) +
        (selected4th ? 7998 : 0) +
        (selected3rd ? 10785 : 0) +
        (selected2nd ? 10968 : 0) +
        (selected1st ? 11030 : 0) +
        (selected0th ? 5978 : 0) +
        (selectedLg ? 12097 : 0)
    );
  }, [
    setArea,
    selected5th,
    selected4th,
    selected3rd,
    selected2nd,
    selected1st,
    selected0th,
    selectedLg
  ]);

  return (
    <StyledAreaCalculator
      key="area"
      animate={{ opacity: 1, transition: { duration: 2 } }}
      initial={{ opacity: 0 }}
      exit={{ opacity: 0, transition: { duration: 1 } }}
      props={{
        showUI,
        main
      }}
    > 
    <div className="innerBox">
        <div className="floorHeader">
          <p>Floor</p>
          <span>Sq Ft</span>
        </div>
        <div className="floors">
          <button
            onClick={() => setSelected5th(!selected5th)}
            className={selected5th ? "active" : undefined}
          >
            <p>Level 05</p>
            <span>5,619</span>
          </button>
          <button
            onClick={() => setSelected4th(!selected4th)}
            className={selected4th ? "active" : undefined}
          >
            <p>Level 04</p>
            <span>7,998</span>
          </button>
          <button
            onClick={() => setSelected3rd(!selected3rd)}
            className={selected3rd ? "active" : undefined}
          >
            <p>Level 03</p>
            <span>10,785</span>
          </button>
          <button
            onClick={() => setSelected2nd(!selected2nd)}
            className={selected2nd ? "active" : undefined}
          >
            <p>Level 02</p>
            <span>10,968</span>
          </button>
          <button
            onClick={() => setSelected1st(!selected1st)}
            className={selected1st ? "active" : undefined}
          >
            <p>Level 01</p>
            <span>11,030</span>
          </button>
          <button
            onClick={() => setSelected0th(!selected0th)}
            className={selected0th ? "active" : undefined}
          >
            <p>Ground</p>
            <span>5,978</span>
          </button>
        </div>
        <div className="floorFooter">
          <p>TOTAL</p>
          <span>{area.toLocaleString()}</span>
        </div>
        {(selected5th ||
          selected4th ||
          selected3rd ||
          selected2nd ||
          selected1st ||
          selected0th ||
          selectedLg) && (
          <button className="download" onClick={handleDownload}>
            Download Plans
          </button>
        )}
      </div>
    </StyledAreaCalculator>
  );
}

const StyledAreaCalculator = styled(motion.div)`
  position: absolute;
  left: 340px;
  top:210px;
  padding: 0;
  z-index: 109;
  width:250px;
  height:500px;
  background-size: cover;
  background-color:#C8C8C8;
  background-position: center;
  background-repeat: no-repeat;
  display: ${({ props }) => (props.main ? "none" : "flex")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: ${({ props }) => (props.showUI ? 1 : 0)} !important;
  transition: all 0.5s ease;

  @media (max-width: 1300px) {
      left: 230px;
  }
  
  @media (max-width: 1000px) {
    width:230px;
  }

  @media (max-width: 880px) {
    top: 150px;
    width:220px;
    height: 420px;
  } 

  @media (max-width: 800px) {
    width:180px;
  } 

  @media (max-height: 800px) {
    top:150px;
  } 

  @media (max-height: 670px) {
    top:120px;
  } 

  @media (max-height: 635px) {
    height: 380px;
  } 

  .innerBox {
    height:90%;
    width:100%;
    margin-top:10%;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    @media (max-width: 880px) {
      margin-top:40%;
    } 

    @media (max-height: 635px) {
      margin-top:30%;
    } 
  }

  .floorHeader,.floorFooter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color:#00703c;
    width: 80%;
    padding:10px 0px;
    text-align: left;
    border: none;
    color: #C8C8C8;
    display: flex;
    align-items:center;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    margin-bottom:20px;

    @media (max-height: 635px) {
      height:10px;
    } 

    p {
        margin-left:21px;       
        font-size: 12px;
        font-family: Sharp Grotesk Book;

        @media (max-width: 1000px) {
          font-size:11px;
        }

        @media (max-width: 880px) {
          font-size:10px;
        } 

        @media (max-height: 635px) {
          font-size:9px;
        } 
    
      }
      span {
        margin-right:20px;
        font-size: 12px;
        font-family: Sharp Grotesk Book;
        color: inherit;

        @media (max-width: 1000px) {
          font-size:11px;
        }

        @media (max-width: 880px) {
          font-size:10px;
        } 

        @media (max-height: 635px) {
          font-size:9px;
        } 
  
      }
  }

  .floorFooter {
    margin-bottom:0px;
    position:relative;
    bottom:140px;
  }

  .floors {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-bottom:155px;
    width: 90%;

    button {
      padding: 0.4rem 0.9rem;
      width: 83%;
      height:30px;
      
      text-align: left;
      border: none;
      background: transparent;
      color: #00703c;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
      
      @media (max-width: 1000px) {
        width: 83%;
      }

      @media (max-height: 635px) {
        height:25px;
      }

      p {
        font-size: 11px;
        font-family: Sharp Grotesk Book;
        font-weight:bolder;

        @media (max-width: 880px) {
          font-size:10px;
        } 
      
        @media (max-height: 635px) {
          font-size:9px;
        } 
      }
      span {
        font-family: Sharp Grotesk Book;
        font-size: 11px;
        font-weight:bolder;
        color: inherit;

        @media (max-width: 880px) {
          font-size:10px;
        } 
     
        @media (max-height: 635px) {
          font-size:9px;
        } 
      }
    }
    .active {
      border:0.5px solid #00703c;
    }

    @media (max-height: 900px) {
       height:460px;
    } 
  }

  .download {
    cursor:pointer;
    position:absolute;
    border:0px;
    bottom:30px;
    width:80%;
    background-color:#00703c;
    height:40px;
    font-family: Sharp Grotesk Book;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #C8C8C8;
    margin: 1rem 0 0;
    font-size:12px;
    svg {
      margin-right: 0.5rem;
    }

    @media (max-width: 880px) {
      font-size:10px;
      margin-top:40%;
      bottom:15px;
    } 

    @media (max-height: 635px) {
      font-size:9px;
      bottom:10px;
      height:30px;
    }
  }

`;
