import { useState } from 'react';
import Availability from '../components/Availability/availabilty.component';
import Building from '../components/Building/building.component';
import Gallery from '../components/Gallery/gallery.component';
import Plans from "../components/Plans/plans.component"
import SideBar from '../components/Sidebar/sideBar.component';
import Specification from '../components/Specification/specification.component';


const NewMain = () => {

  const [currentContent,setCurrentContent] = useState("building")
  const [previousContent,setPreviousContent] = useState(null)
  const [selectedFloor,setSelectedFloor] = useState(null)  

  return (
    <div className="newApp">
      <div className='sidebar-app'>
        <SideBar setSelectedFloor = {setSelectedFloor} setCurrentContent = {setCurrentContent} setPreviousContent = {setPreviousContent} currentContent = {currentContent}/>
      </div>
      <div className="brainYardlogo"/>
      <div className='app-content' style={{background:"#062C26"}}>     
        <Building currentContent = {currentContent} setPreviousContent = {setPreviousContent} setCurrentContent = {setCurrentContent}/>
        <Plans selectedFloor = {selectedFloor} setPreviousContent = {setPreviousContent} setSelectedFloor = {setSelectedFloor} setCurrentContent = {setCurrentContent} currentContent = {currentContent}/>
        <Gallery currentContent = {currentContent} />
        <Availability currentContent = {currentContent}/>
        <Specification currentContent = {currentContent}/>
      </div>
    </div>
  );
}

export default NewMain;
