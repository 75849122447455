import create from "zustand";

export const useStore = create((set) => ({
  menu: false,
  track: 0,
  isAnimating: false,
  area: 0,
  modal: 0,
  selected10th: false,
  selected9th: false,
  selected8th: false,
  selected7th: false,
  selected6th: false,
  selected5th: false,
  selected4th: false,
  selected3rd: false,
  selected2nd: false,
  selected1st: false,
  selected0th: false,
  selectedLg: false,
  vrModal: false,
  showUI: true,
  showGallery: false,
  plansGallery:false,
  currentFloor:6,
  setMenu: (menu) => set((state) => ({ ...state, menu })),
  setTrack: (num) => set((state) => ({ ...state, track: num })),
  setIsAnimating: (bool) => set((state) => ({ ...state, isAnimating: bool })),
  setArea: (num) => set((state) => ({ ...state, area: num })),
  setModal: (num) => set((state) => ({ ...state, modal: num })),
  setSelected10th: (bool) => set((state) => ({ ...state, selected10th: bool })),
  setSelected9th: (bool) => set((state) => ({ ...state, selected9th: bool })),
  setSelected8th: (bool) => set((state) => ({ ...state, selected8th: bool })),
  setSelected7th: (bool) => set((state) => ({ ...state, selected7th: bool })),
  setSelected6th: (bool) => set((state) => ({ ...state, selected6th: bool })),
  setSelected5th: (bool) => set((state) => ({ ...state, selected5th: bool })),
  setSelected4th: (bool) => set((state) => ({ ...state, selected4th: bool })),
  setSelected3rd: (bool) => set((state) => ({ ...state, selected3rd: bool })),
  setSelected2nd: (bool) => set((state) => ({ ...state, selected2nd: bool })),
  setSelected1st: (bool) => set((state) => ({ ...state, selected1st: bool })),
  setSelected0th: (bool) => set((state) => ({ ...state, selected0th: bool })),
  setSelectedLg: (bool) => set((state) => ({ ...state, selectedLg: bool })),
  setVrModal: (bool) => set((state) => ({ ...state, vrModal: bool })),
  setShowUI: (bool) => set((state) => ({ ...state, showUI: bool })),
  setShowGallery: (bool) => set((state) => ({ ...state, showGallery: bool })),
  setPlansGallery: (bool) => set((state) => ({ ...state, plansGallery: bool })),
  setCurrentFloor:(val) => set((state) => ({...state,currentFloor:val}))
}));
