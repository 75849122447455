import React from "react";
import { useStore } from "../../store/store";
import "./sideBar.styles.css"

const SideBar = ({setCurrentContent,setPreviousContent,currentContent,setSelectedFloor}) => {

    const {setCurrentFloor} = useStore()

    const handleClick = (e) =>
    {
        
        let newVal = e.target.innerHTML.toString().toLowerCase();
        if(newVal === "the building")
        {
            newVal = "building"
        }

        if(newVal === "plans")
        {
            setSelectedFloor(null)
        }

        if(currentContent === newVal)
        {
            return
        }

        setPreviousContent(currentContent)
        setCurrentContent(newVal)
    }

    let StyleValPlans = {}
    let galleryStyles = {}
    let availabilityStyles = {}
    let specStyles = {}
    if(currentContent === "plans" )
    {
        StyleValPlans = {
            top:"4.5%",
            visibility:"visible"
        }

    }

    if(currentContent === "gallery")
    {
        StyleValPlans = {
            top:"4.5%",
            visibility:"visible"
        }

        galleryStyles = {
            top:"26%",
            visibility:"visible"
        }
    }

    if(currentContent === "availability")
    {
        StyleValPlans = {
            top:"4.5%",
            visibility:"visible"
        }

        galleryStyles = {
            top:"26%",
            visibility:"visible"
        }

        availabilityStyles = {
            top:"48%",
            visibility:"visible"
        }
    }

    if(currentContent === "specification")
    {
        StyleValPlans = {
            top:"4.5%",
            visibility:"visible"
        }

        galleryStyles = {
            top:"26%",
            visibility:"visible"
        }

        availabilityStyles = {
            top:"48%",
            visibility:"visible"
        }

        specStyles = {
            top:"70%",
            visibility:"visible"
        }
    }

    return(
        <div className="sideBar">
            <div className="buildingText" style={{top:"4.5%",color:`${currentContent === "building" ? "#FF7B4A" :"#2B4B47"}`}} onClick = {handleClick} >The Building</div>
            <div className="outerDot" style={{top:"4%",background:"#FF7B4A"}}>
                <div className="innerDot"></div>
            </div>

            <div className="initialTube"/>

            <div className="leftlineBuilding" style={{...StyleValPlans}}/>
            <div className="rightlineBuilding" style={{...StyleValPlans}}/>

            <div className="plansText" style={{top:"26.5%",color:`${currentContent === "plans" ? "#2FB292" :"#2B4B47"}`}} onClick = {handleClick}>Plans</div>
            <div className="outerDot" style={{top: "26%",background:`${currentContent === "plans" || currentContent === "gallery" || currentContent === "availability" || currentContent === "specification" ? "#2FB292" :"#B2B2B2"}`}}>
                <div className="innerDot"></div>
            </div>
            <div className="leftlinePlans" style={{...galleryStyles}}/>
            <div className="rightlinePlans" style={{...galleryStyles}}/>

            <div className="galleryText" style={{top:"48.5%",color:`${currentContent === "gallery" ? "#EF455B" :"#2B4B47"}`}} onClick = {handleClick}>Gallery</div>
            <div className="outerDot" style={{top: "48%",background:`${currentContent === "gallery" || currentContent === "availability" || currentContent === "specification"  ? "#EF455B" :"#B2B2B2"}`}}>
                <div className="innerDot"></div>
            </div>

            <div className="leftlineGallery" style={{...availabilityStyles}}/>
            <div className="rightlineGallery" style={{...availabilityStyles}}/>

            <div className="availabilityText" style={{top:"70.5%",color:`${currentContent === "availability"  ? "#008ABC" :"#2B4B47"}`}} onClick = {handleClick}>Availability</div>
            <div className="outerDot" style={{top: "70%", background:`${currentContent === "availability" || currentContent === "specification" ? "#008ABC" :"#B2B2B2"}`}}>
                <div className="innerDot"></div>
            </div>


            <div className="leftlineAvail" style={{...specStyles}}/>
            <div className="rightlineAvail" style={{...specStyles}}/>
        

            <div className="specText" style={{top:"94.5%",color:`${currentContent === "specification" ? "#FF7B4A" :"#2B4B47"}`}} onClick = {handleClick}>Specification</div>
            <div className="outerDotFinal" style={{top: "94%",background:`${currentContent === "specification" ? "#FF7B4A" :"#B2B2B2"}`}}>
                <div className="innerDot"></div>
            </div>
        </div>
    )
}

export default SideBar
